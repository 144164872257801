/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useErrorBoundary } from 'use-error-boundary';
import { useRouter } from 'next/router';
import { GlobalStyle } from 'styles/styles';
import { GlobalContext, Themes } from '../context/global-context';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'auth/config';
import AccountHandler from 'components/account-handler';
import Cookies from 'js-cookie';
import { ACCESSTOKEN_COOKIE_NAME } from 'utils/constants';
import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
  teamsHighContrastTheme,
  SSRProvider,
} from '@fluentui/react-components';

const base = 'learn.continia.com';

export const AppWrapper: React.FC = ({ children }) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary({
    onDidCatch: (error, errorInfo) => {
      console.log('error, errorInfo', error, errorInfo);
    },
  });
  const { locales, defaultLocale } = useRouter();
  const [theme, setTheme] = useState<Themes>('light');
  const [accessToken, setAccessToken] = useState('');
  const [achievements, setAchievements] = useState<UserAchievement[]>([]);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Catch errors
  useEffect(() => {
    if (didCatch) {
      console.log(error);
    }
  }, [didCatch, error]);

  // Set theme
  useEffect(() => {
    document.querySelector('html').className = '';
    document.querySelector('html').className = `theme-${theme}`;
  }, [theme]);

  useEffect(() => {
    if (Cookies.get(ACCESSTOKEN_COOKIE_NAME)) {
      setAccessToken(Cookies.get(ACCESSTOKEN_COOKIE_NAME));
    }
  }, []);

  const getTheme = (): object => {
    switch (true) {
      case theme === 'light':
        return {
          ...webLightTheme,
          colorBrandBackground: '#052975',
          colorCompoundBrandBackground: '#052975',
          colorCompoundBrandStroke: '#052975',
          colorCompoundBrandForeground1: '#052975',
          colorBrandForegroundLink: '#052975',
          colorBrandForegroundLinkHover: '#00518f',
          colorCompoundBrandForeground1Hover: '#00518f',
          colorBrandBackgroundHover: '#00518f',
          colorCompoundBrandBackgroundHover: '#00518f',
        };
      case theme === 'dark':
        return webDarkTheme;
      case theme === 'high-contrast':
        return teamsHighContrastTheme;
      default:
        return;
    }
  };

  return (
    <MsalProvider instance={msalInstance}>
      <GlobalContext.Provider
        value={{
          setTheme,
          theme,
          accessToken,
          setAccessToken,
          achievements,
          setAchievements,
          bookmarks,
          setBookmarks,
          dataLoaded,
          setDataLoaded,
        }}
      >
        {didCatch ? (
          <p>An error has been catched: {error.message}</p>
        ) : (
          <ErrorBoundary>
            <Head>
              {/* <meta name="robots" content={process.env.NEXT_PUBLIC_STAGING ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW'} /> */}
              <meta name="robots" content='INDEX, FOLLOW' />
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
              <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
              <meta property="og:site_name" content="Continia Learn" />
              <meta property="og:site" content="https://learn.continia.com" />
              {locales.map((loc) => {
                return (
                  <link
                    key={loc}
                    rel="alternate"
                    hrefLang={loc}
                    href={`https://${base}/${loc !== defaultLocale ? loc : ''}`}
                  />
                );
              })}
              <link
                rel="alternate"
                href={`https://${base}/${defaultLocale !== 'en-US' ? 'en-US' : ''}`}
                hrefLang="x-default"
              />
            </Head>
            <AccountHandler />
            {/* @ts-ignore */}
            <GlobalStyle />
            <SSRProvider>
              <FluentProvider theme={getTheme()}>
                <div className={`app`}>{children}</div>
              </FluentProvider>
            </SSRProvider>
          </ErrorBoundary>
        )}
      </GlobalContext.Provider>
    </MsalProvider>
  );
};
