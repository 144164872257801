import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import profileApi from '../apiFrontend/profileApi';
import systemApi from '../apiFrontend/systemApi';
import assessmentStatusApi from '../apiFrontend/assessmentStatusApi';
import assessmentApi from '../apiFrontend/assessmentApi';

export const store = configureStore({
  reducer: {
    [systemApi.reducerPath]: systemApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [assessmentStatusApi.reducerPath]: assessmentStatusApi.reducer,
    [assessmentApi.reducerPath]: assessmentApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([systemApi.middleware, profileApi.middleware, assessmentStatusApi.middleware, assessmentApi.middleware]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
