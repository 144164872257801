import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'auth/config';
import { useEffect } from 'react';
import { ACCESSTOKEN_COOKIE_NAME, IS_PRODUCTION } from 'utils/constants';
import { getQueue, clearQueue, useUserData } from 'utils/user';
import styled from 'styled-components';
import Cookies from 'js-cookie';

type IdTokenClaimsType = {
  exp: number;
};

export const AccountHandler: React.FC = () => {
  const {
    accounts,
    instance,
    inProgress,
    setAccessToken,
    accessToken,
    setBookmarks,
    achievements,
    setAchievements,
    setDataLoaded,
    init,
  } = useUserData();

  const onAccessTokenResponse = async (newAccessToken: string): Promise<void> => {
    // Set cookie to use accessToken in all /api requests
    Cookies.set(ACCESSTOKEN_COOKIE_NAME, newAccessToken, {
      sameSite: 'strict',
      secure: process.env.NODE_ENV === 'production',
    });

    // Set access token globally
    setAccessToken(newAccessToken);

    // Get queue
    const queue = getQueue();

    // It's okay to send empty array - we get achievements from the server
    const res = await fetch(`/api/set-bulk-actions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: queue }),
    });

    if (res.status === 200) {
      const newAchievements = await res.json();
      setAchievements(newAchievements);
      setDataLoaded(true);
      clearQueue();
    } else {
      console.log('// ERROR FROM BULK', await res.json());
    }

    // Fetching bookmarks
    const bookmarkRes = await fetch(`/api/get-bookmarks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: queue }),
    });

    if (bookmarkRes.status === 200) {
      const bookmarks = await bookmarkRes.json();
      setBookmarks(bookmarks);
    } else {
      console.log('// ERROR FROM bookmarks', await bookmarkRes.json());
    }
  };

  // Set access token
  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      const expireDate = new Date((accounts[0].idTokenClaims as IdTokenClaimsType).exp * 1000);

      // Check expire date
      if (expireDate > new Date()) {
        // Retrieve an access token
        instance
          .acquireTokenSilent({
            account: accounts[0],
            ...loginRequest,
          })
          .then((response) => {
            console.log('acquireTokenSilent response', response);

            if (response.accessToken) {
              onAccessTokenResponse(response.accessToken);
            }
          })
          .catch((reason) => {
            console.log('@@@ Error: ', reason);
          });
      } else {
        // If expired logout
        instance.logout({
          onRedirectNavigate: (url) => {
            console.log('@@@ logout: ', url);
            return false;
          },
        });
      }
    }
    if (inProgress === InteractionStatus.None && accounts.length === 0) {
      init();
    }
  }, [inProgress, accounts, instance]); // eslint-disable-line

  if (IS_PRODUCTION) {
    return null;
  }

  return (
    <StyledAccountHandler>
      <span>i</span>
      <div className="content">
        {accessToken && (
          <div className="single-item item">
            <div className="label">AccessToken</div>
            <div className="value">
              <textarea defaultValue={accessToken}></textarea>
            </div>
          </div>
        )}
        <div className="single-item item">
          <div className="label">inProgress</div>
          <div className="value">{inProgress}</div>
        </div>

        {achievements?.length > 0 && (
          <>
            <h3>Achievemnets</h3>
            <div className="grid">
              {achievements.map((achievement) => (
                <div className="grid-item item" key={achievement.contentId}>
                  <div className="label">{achievement.type}</div>
                  <div className="value">{achievement.contentId}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </StyledAccountHandler>
  );
};

const StyledAccountHandler = styled.aside`
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: 30px;
  background: aquamarine;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  display: none;

  span {
    font-family: monospace;
    font-size: 2rem;
  }

  .content {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: none;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .item {
      .label {
        font-size: 0.8rem;
        font-weight: bold;
      }
      .value {
      }
    }
  }

  &:hover {
    width: 60vw;
    height: 80vh;
    border-radius: 4px;
    align-items: flex-start;

    > span {
      display: none;
    }

    .content {
      opacity: 1;
    }
  }
`;

export default AccountHandler;
