// import { useRouter } from 'next/router';
import { Fragment, useState, useEffect } from 'react';
// import i18n, { SettingsType } from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import {
  makeStyles,
  tokens,
  shorthands,
  Subtitle2,
  Title2,
  Button,
  Dialog,
  DialogSurface,
  DialogActions,
  Input,
  Radio,
  RadioGroup,
  useId,
  Switch,
  Tooltip,
  Link,
  Caption1,
} from '@fluentui/react-components';
import profileApi from '../../apiFrontend/profileApi';
import systemApi from '../../apiFrontend/systemApi';

enum Inputs {
  address = 'address',
  pass = 'pass',
  type = 'type',
  partnerLinked = 'partnerLinked',
}

enum UserTypes {
  partner = 'Partner',
  user = 'EndUser',
  other = 'Other',
}

const useOverrides = makeStyles({
  btn: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    minWidth: 'auto',
  },
  field: {
    display: 'grid',
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: tokens.spacingVerticalXL,
    marginTop: tokens.spacingVerticalL,
  },
  logins: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    marginBottom: tokens.spacingVerticalS,
    marginTop: tokens.spacingVerticalXS,
  },
  input: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },
  btnContainer: {
    justifyContent: 'flex-end',
  },
  dialog: {
    maxWidth: '510px',
  },
  switchContainer: {
    marginBottom: tokens.spacingVerticalXL,
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: tokens.spacingVerticalXL,
  },
  error: {
    color: 'var(--danger-base)',
    position: 'absolute',
    marginBottom: tokens.spacingVerticalXL,
    '& p': {
      fontSize: '14px',
      // fontWeight: 600,
    },
  },
  text: {
    marginBottom: tokens.spacingVerticalXL,
    marginTop: tokens.spacingVerticalS,
  },
});

const FirstTimeLogin: React.FC = () => {
  const overrides = useOverrides();
  const labelId = useId('label');
  // const { locale } = useRouter();
  // const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;
  const [showModal, setShowModal] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [postProfile, { isSuccess, isError, error }] = profileApi.usePostProfileMutation();
  const [profileApiTrigger] = profileApi.useLazyGetProfileQuery();
  const { handleSubmit, control, watch } = useForm({
    // resolver: yupResolver(emailSchema),
    defaultValues: {
      type: '',
      address: '',
      pass: '',
      partnerLinked: true,
    },
  });

  const type = watch(Inputs.type);
  const email = watch(Inputs.address);
  const password = watch(Inputs.pass);
  const linked = watch(Inputs.partnerLinked);

  useEffect(() => {
    return setSubmitDisabled(isDisabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, email, password, linked]);

  useEffect(() => {
    if (isSuccess) {
      return setShowModal(false);
    }
  }, [isSuccess, profileApiTrigger]);

  const onSubmit = (data: any) => {
    postProfile({
      email: data.type === UserTypes.partner && data.partnerLinked ? data.address : null,
      password: data.type === UserTypes.partner && data.partnerLinked ? data.pass : null,
      userType: data.type,
      linked: data.type === UserTypes.partner ? data.partnerLinked : false,
    });
  };

  const radios = [
    {
      value: UserTypes.partner,
      label: 'I work for a Continia partner',
    },
    {
      value: UserTypes.user,
      label: "I'm an end-user",
    },
    {
      value: UserTypes.other,
      label: 'Other',
    },
  ];

  const isDisabled = (): boolean => {
    switch (true) {
      case !!type && type !== UserTypes.partner:
        return false;
      case type === UserTypes.partner && !!password && !!email && linked:
        return false;
      case !!type && !linked:
        return false;
      default:
        return true;
    }
  };

  return (
    <Dialog modalType="alert" open={showModal}>
      <DialogSurface className={overrides.dialog}>
        <form onSubmit={handleSubmit((data: any) => onSubmit(data))} autoComplete="off">
          <Title2 as="h2">Help us personalize your experience</Title2>
          <div className={overrides.field}>
            <Subtitle2 as="h6" id={labelId}>
              Which option best describes you?
            </Subtitle2>
            <RadioGroup aria-labelledby={labelId}>
              <Controller
                control={control}
                name={Inputs.type}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <Fragment>
                    {radios.map((option) => (
                      <Radio
                        key={option.value}
                        {...rest}
                        onChange={onChange}
                        value={option.value}
                        label={option.label}
                      />
                    ))}
                  </Fragment>
                )}
              />
            </RadioGroup>
          </div>
          {watch(Inputs.type) === UserTypes.partner && (
            <div>
              <div className={overrides.switchContainer}>
                <Controller
                  control={control}
                  name={Inputs.partnerLinked}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <Tooltip
                      withArrow
                      content="Link your Continia PartnerZone account with your Learn profile and get the most out of our partnership."
                      relationship="label"
                    >
                      <Switch
                        label="Link my Continia PartnerZone and Learn profiles."
                        checked={value}
                        onChange={onChange}
                        {...rest}
                      />
                    </Tooltip>
                  )}
                />
              </div>
              {!!watch(Inputs.partnerLinked) && (
                <>
                  <Subtitle2 as="h6">Please enter your Continia PartnerZone credentials</Subtitle2>
                  <p className={overrides.text}>When you associate your Learn account with a Continia partner, please note that your achievements may be shared with your organization. You can remove the link in the profile settings at any time.</p>
                  <div className={overrides.logins}>
                    <Controller
                      control={control}
                      name={Inputs.address}
                      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                        <Input
                          {...rest}
                          onChange={onChange}
                          className={overrides.input}
                          type="text"
                          placeholder="Email"
                          size="large"
                          autoComplete="off"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={Inputs.pass}
                      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                        <Input
                          {...rest}
                          onChange={onChange}
                          className={overrides.input}
                          type="password"
                          placeholder="Password"
                          size="large"
                          autoComplete="new-password"
                        />
                      )}
                    />
                  </div>
                  {isError && 'data' in error && (
                    <div className={overrides.error}>
                      {/* @ts-ignore */}
                      <Caption1 as="p">{error.data.message}</Caption1>
                    </div>
                  )}
                  <div className={overrides.link}>
                    <Link as="a" href="https://partnerzone.continia.com/request-reset-password" target="_blank">
                      Forgot password?
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
          <DialogActions className={overrides.btnContainer}>
            <Button
              className={overrides.btn}
              appearance="primary"
              shape="rounded"
              size="medium"
              type="submit"
              disabled={submitDisabled}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

export default FirstTimeLogin;
