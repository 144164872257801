import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from './msal';

const baseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: async (headers) => {
    const token = await getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export default baseQuery;
