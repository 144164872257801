export const IS_PRODUCTION = process.env.NODE_ENV !== 'development';

export const IS_BROWSER = typeof window !== 'undefined';

export const WORD_PR_MINUTE = 130;

export const ACCESSTOKEN_COOKIE_NAME = 'continia_accesstoken';

export const TAG_LABELS: TagLabels = {
  DC: 'Document Capture',
  PM: 'Payment Management',
  EM: 'Expense Management',
  DO: 'Document Output',
  FIN: 'Finance',
  SUS: 'Sustainability',
};

export const frontpageIds = {
  frontpage: 'AiA55lctU3dpAQAnWZej0',
  tv: '1ezqkgXRK3SImTbSJ6LR3U',
  browse: 'QVW4bjAYyEvmA7x2Amfcy',
  certificates: 'u19Ryc6FrWFPEOC1YVHW5',
};

// Bruges kun til at metadata er korrekt
export const frontpagePaths = {
  AiA55lctU3dpAQAnWZej0: '/',
  '1ezqkgXRK3SImTbSJ6LR3U': '/tv',
  QVW4bjAYyEvmA7x2Amfcy: '/browse',
  u19Ryc6FrWFPEOC1YVHW5: '/certificates',
};

export const products: LightProduct[] = [
  {
    tag: 'DC',
    slug: 'document-capture',
    title: TAG_LABELS['DC'],
    youtubePlaylistId: ['PLo9-ZCpEB9gtiD7cHkZjxbfq9O7ArK_Rl', 'PLo9-ZCpEB9gteYlPQs2JMiicgkSMMj2I1'],
  },
  {
    tag: 'DO',
    slug: 'document-output',
    title: TAG_LABELS['DO'],
    youtubePlaylistId: [],
  },
  {
    tag: 'EM',
    slug: 'expense-management',
    title: TAG_LABELS['EM'],
    youtubePlaylistId: [
      'PLo9-ZCpEB9gsPpgzjD1-KFlRzS4sJPkU6',
      'PLo9-ZCpEB9gtTsEYJO6SUuGPU5wbHVYkv',
      'PLo9-ZCpEB9gsGNKowsWE4G2y9N8UGrOga',
    ],
  },
  {
    tag: 'PM',
    slug: 'payment-management',
    title: TAG_LABELS['PM'],
    youtubePlaylistId: [],
  },
];

// Kopier til next.config.js
export const LANGUAGES = [
  {
    key: 'en-US',
    label: 'English',
  },

  {
    key: 'de',
    label: 'German',
  },
];
