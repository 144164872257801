import { AppWrapper } from 'layouts/app-wrapper';
import SystemProfile from '../wrappers/SystemProfile';
// import type { AppProps } from 'next/app';
import React from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../utils/AppInsights';
import { createDOMRenderer, RendererProvider } from '@griffel/react';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import 'styles/fonts.css';
import 'styles/icons.css';
import 'styles/reset.css';
import 'styles/normalize.css';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const GlobalApp = ({ Component, pageProps, renderer }: any) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Provider store={store}>
        <RendererProvider renderer={renderer || createDOMRenderer()}>
          <AppWrapper>
            <SystemProfile>
              <Component {...pageProps} />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Corporation',
                    name: 'Continia Software',
                    url: 'https://www.continia.com',
                  }),
                }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'WebSite',
                    name: 'Continia Learn',
                    url: 'https://learn.continia.com',
                    potentialAction: {
                      '@type': 'SearchAction',
                      target: `https://learn.continia.com/search?q={search_term_string}`,
                      'query-input': 'required name=search_term_string',
                    },
                  }),
                }}
              />
            </SystemProfile>
          </AppWrapper>
        </RendererProvider>
      </Provider>
    </AppInsightsContext.Provider>
  );
};

export default GlobalApp;
