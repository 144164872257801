import * as msal from '@azure/msal-browser';
import { msalInstance, loginRequest } from 'auth/config';

export async function getAccessToken() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) throw new Error('No accounts found');

  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    if (error instanceof msal.InteractionRequiredAuthError) {
      return msalInstance.acquireTokenPopup(request).then((response) => response.accessToken);
    } else {
      throw error;
    }
  }
}
