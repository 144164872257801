import { createContext } from 'react';

export type Themes = 'light' | 'dark' | 'high-contrast';

export interface ContextProps {
  accessToken: string;
  setAccessToken?: React.Dispatch<React.SetStateAction<string>>;
  theme: Themes;
  setTheme?: React.Dispatch<React.SetStateAction<Themes>>;
  achievements: UserAchievement[];
  setAchievements?: React.Dispatch<React.SetStateAction<UserAchievement[]>>;
  bookmarks: Bookmark[];
  setBookmarks?: React.Dispatch<React.SetStateAction<Bookmark[]>>;
  dataLoaded: boolean;
  setDataLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalContext = createContext<ContextProps>({
  theme: 'light',
  accessToken: '',
  achievements: [],
  bookmarks: [],
  dataLoaded: false,
});
