import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ResponseData {
  id: string;
  emails: string;
  provider: string;
  'base-url': string;
  'data-center': string;
}

export const systemApi = createApi({
  reducerPath: 'system',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/system/',
  }),
  endpoints: (builder) => ({
    getSystem: builder.query<ResponseData, void>({
      query() {
        return {
          url: '/get-system-user-info',
          method: 'GET',
        };
      },
    }),
    deleteSystem: builder.mutation<any, void>({
      query() {
        return {
          url: '/delete-system-user-info',
          method: 'DELETE',
        };
      },
    }),
  }),
});

export default systemApi;
