import { createGlobalStyle, css } from 'styled-components';

export const deviceSizes = {
  smallMobile: 480,
  mobile: 768,
  tablet: 860,
  desktop: 1084,
  wide: 1284,
  ultrawide: 1795,
};

type MQ = {
  smallMobile: string;
  mobile: string;
  tablet: string;
  desktop: string;
  wide: string;
  ultrawide: string;
};

export const mqFrom: MQ = {
  smallMobile: `@media (min-width: ${deviceSizes.smallMobile}px)`,
  mobile: `@media (min-width: ${deviceSizes.mobile}px)`,
  tablet: `@media (min-width: ${deviceSizes.tablet}px)`,
  desktop: `@media (min-width: ${deviceSizes.desktop}px)`,
  wide: `@media (min-width: ${deviceSizes.wide}px)`,
  ultrawide: `@media (min-width: ${deviceSizes.ultrawide}px)`,
};

export const mqUntil: MQ = {
  smallMobile: `@media (max-width: ${deviceSizes.smallMobile}px)`,
  mobile: `@media (max-width: ${deviceSizes.mobile}px)`,
  tablet: `@media (max-width: ${deviceSizes.tablet}px)`,
  desktop: `@media (max-width: ${deviceSizes.desktop}px)`,
  wide: `@media (max-width: ${deviceSizes.wide}px)`,
  ultrawide: `@media (max-width: ${deviceSizes.ultrawide}px)`,
};

export const shadows = {
  right: `box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);`,
  left: `box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);`,
  bottom: `box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);`,
  top: `box-shadow: 0px -5px 15px 0px rgba(0,0,0,0.1);`,
  modal: `box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);`,
};

interface GlobalStyleProps {
  theme: string;
}

const themeLight = css`
  .theme-dark {
    --text: #e3e3e3;
    --text-subtle: #c7c7c7;
    --text-invert: #2e2e2e;
    --text-glow-high-contrast: #e3e3e3;
    --box-shadow-light: rgba(255, 255, 255, 0.108);
    --box-shadow-medium: rgba(255, 255, 255, 0.132);
    --box-shadow-heavy: rgba(255, 255, 255, 0.18);
    --box-shadow-extra-heavy: rgba(255, 255, 255, 0.22);
    --body-background: #171717;
    --body-background-medium: #2e2e2e;
    --body-background-dark: #242424;
    --border: #454545;
    --hover-base: #c7c7c7;
    --hover-invert: #2e2e2e;
    --border-white-high-contrast: rgba(255, 255, 255, 0);
    --border-yellow-high-contrast: rgba(0, 0, 0, 0);
    --visited: #8278de;
    --score-low-off: rgba(218, 59, 1, 0.7);
    --score-low: #da3b01;
    --score-medium-off: rgba(255, 187, 0, 0.3);
    --score-medium: #ffc933;
    --score-high-off: rgba(18, 135, 18, 0.7);
    --score-high: #17b517;
    --primary-base: #4db2ff;
    --unit-link: #0078d4;
    --primary-background: #002b4d;
    --primary-background-glow-high-contrast: #002b4d;
    --primary-dark: #a3d7ff;
    --primary-hover: #0091ff;
    --primary-active: #0065b3;
    --primary-box-shadow: rgba(0, 101, 179, 0.3);
    --primary-invert: black;
    --secondary-base: #757575;
    --secondary-background: #2e2e2e;
    --secondary-background-glow-high-contrast: #2e2e2e;
    --secondary-dark: #e3e3e3;
    --secondary-hover: #919191;
    --secondary-active: #ababab;
    --secondary-box-shadow: rgba(0, 0, 0, 0.7);
    --secondary-invert: white;
    --success-base: #128712;
    --success-background: #094409;
    --success-background-glow-high-contrast: #094409;
    --success-dark: #d2f9d2;
    --success-hover: #17b517;
    --success-active: #4ae84a;
    --success-box-shadow: rgba(18, 135, 18, 0.7);
    --success-invert: black;
    --info-base: #8763c5;
    --info-background: #38225d;
    --info-background-glow-high-contrast: #38225d;
    --info-dark: #e2daf1;
    --info-hover: #9b7dcf;
    --info-active: #a98fd6;
    --info-box-shadow: rgba(38, 23, 63, 0.7);
    --info-invert: white;
    --warning-base: #ffbb00;
    --warning-background: #664b00;
    --warning-background-glow-high-contrast: #664b00;
    --warning-dark: #fff1cc;
    --warning-hover: #ffc933;
    --warning-active: #ffd666;
    --warning-box-shadow: rgba(255, 187, 0, 0.7);
    --warning-invert: black;
    --danger-base: #da3b01;
    --danger-background: #651b01;
    --danger-background-glow-high-contrast: #651b01;
    --danger-dark: #ffdacc;
    --danger-hover: #fe571b;
    --danger-active: #fe8f67;
    --danger-box-shadow: rgba(218, 59, 1, 0.7);
    --danger-invert: white;
    --code-highlight-background: #0c5a0c;
  }
`;

const themeHighContrast = css`
  .theme-high-contrast {
    --code-highlight-background: #0c5a0c;
    --text: white;
    --text-subtle: white;
    --text-invert: black;
    --text-glow-high-contrast: yellow;
    --box-shadow-light: rgba(255, 255, 255, 0);
    --box-shadow-medium: rgba(255, 255, 255, 0);
    --box-shadow-heavy: rgba(255, 255, 255, 0);
    --box-shadow-extra-heavy: rgba(255, 255, 255, 0);
    --overlay: white;
    --body-background: black;
    --body-background-medium: black;
    --body-background-dark: black;
    --border: white;
    --hover-base: yellow;
    --hover-invert: black;
    --border-white-high-contrast: white;
    --border-yellow-high-contrast: yellow;
    --visited: #3cff00;
    --score-low-off: #b23001;
    --score-low: #ffdacc;
    --score-medium-off: rgba(255, 187, 0, 0.7);
    --score-medium: #ffe499;
    --score-high-off: #0f710f;
    --score-high: #d2f9d2;
    --primary-base: yellow;
    --unit-link: yellow;
    --primary-background: black;
    --primary-background-glow-high-contrast: yellow;
    --primary-dark: yellow;
    --primary-hover: #ffff33;
    --primary-active: #ffff33;
    --primary-box-shadow: white;
    --primary-invert: black;
    --secondary-base: #e3e3e3;
    --secondary-background: black;
    --secondary-background-glow-high-contrast: #5e5e5e;
    --secondary-dark: #e3e3e3;
    --secondary-hover: #c7c7c7;
    --secondary-active: #c7c7c7;
    --secondary-box-shadow: #e3e3e3;
    --secondary-invert: black;
    --tertiary-base: white;
    --tertiary-background: black;
    --tertiary-background-glow-high-contrast: white;
    --tertiary-dark: white;
    --tertiary-hover: #e3e3e3;
    --tertiary-active: #e3e3e3;
    --tertiary-box-shadow: white;
    --tertiary-invert: black;
    --success-base: #a5f3a5;
    --success-background: black;
    --success-background-glow-high-contrast: #a5f3a5;
    --success-dark: #d2f9d2;
    --success-hover: #d2f9d2;
    --success-active: #d2f9d2;
    --success-box-shadow: white;
    --success-invert: black;
    --info-base: #c6b5e3;
    --info-background: black;
    --info-background-glow-high-contrast: #c6b5e3;
    --info-dark: #e2daf1;
    --info-hover: #e2daf1;
    --info-active: #e2daf1;
    --info-box-shadow: white;
    --info-invert: black;
    --warning-base: #ffe499;
    --warning-background: black;
    --warning-background-glow-high-contrast: #ffe499;
    --warning-dark: #fff1cc;
    --warning-hover: #e2daf1;
    --warning-active: #e2daf1;
    --warning-box-shadow: white;
    --warning-invert: black;
    --danger-base: #feb49a;
    --danger-background: black;
    --danger-background-glow-high-contrast: #feb49a;
    --danger-dark: #ffdacc;
    --danger-hover: #ffdacc;
    --danger-active: #ffdacc;
    --danger-box-shadow: white;
    --danger-invert: black;
  }
`;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  :root {
    --overlay: rgba(0, 0, 0, 0.7);
    --text: #171717;
    --text-subtle: #5e5e5e;
    --text-invert: #ffffff;
    --text-glow-high-contrast: #171717;
    --box-shadow-light: rgba(0, 0, 0, 0.108);
    --box-shadow-medium: rgba(0, 0, 0, 0.132);
    --box-shadow-heavy: rgba(0, 0, 0, 0.18);
    --box-shadow-extra-heavy: rgba(0, 0, 0, 0.22);
    --body-background: white;
    --body-background-medium: #f2f2f2;
    --body-background-dark: #f2f2f2;
    --border: #e3e3e3;
    --hover-base: #2e2e2e;
    --hover-invert: #fafafa;
    --border-white-high-contrast: rgba(255, 255, 255, 0);
    --border-yellow-high-contrast: rgba(0, 0, 0, 0);
    --code-highlight-background: #fff1cc;
    --visited: #660099;
    --score-low-off: rgba(218, 59, 1, 0.3);
    --score-low: #da3b01;
    --score-medium-off: rgba(255, 187, 0, 0.3);
    --score-medium: #ffbb00;
    --score-high-off: rgba(18, 135, 18, 0.3);
    --score-high: #128712;
    --primary-base: #052975;
    --unit-link: #75b6e7;
    --primary-background: #e0f2ff;
    --primary-background-glow-high-contrast: #e0f2ff;
    --primary-dark: #002b4d;
    --primary-hover: #00518f;
    --primary-active: #003a66;
    --primary-box-shadow: rgba(0, 101, 179, 0.3);
    --primary-invert: white;
    --secondary-base: #ababab;
    --secondary-background: #e3e3e3;
    --secondary-background-glow-high-contrast: #e3e3e3;
    --secondary-dark: #2e2e2e;
    --secondary-hover: #c7c7c7;
    --secondary-active: #c7c7c7;
    --secondary-box-shadow: rgba(0, 0, 0, 0.3);
    --secondary-invert: black;
    --success-base: #128712;
    --success-background: #d2f9d2;
    --success-background-glow-high-contrast: #d2f9d2;
    --success-dark: #094409;
    --success-hover: #0f710f;
    --success-active: #0c5a0c;
    --success-box-shadow: rgba(18, 135, 18, 0.3);
    --success-invert: white;
    --info-base: #8763c5;
    --info-background: #e2daf1;
    --info-background-glow-high-contrast: #e2daf1;
    --info-dark: #38225d;
    --info-hover: #7045ba;
    --info-active: #53338a;
    --info-box-shadow: #e2daf1;
    --info-invert: white;
    --warning-base: #ffbb00;
    --warning-background: #fff1cc;
    --warning-background-glow-high-contrast: #fff1cc;
    --warning-dark: #664b00;
    --warning-hover: #cc9600;
    --warning-active: #997000;
    --warning-box-shadow: rgba(255, 187, 0, 0.3);
    --warning-invert: black;
    --danger-base: #da3b01;
    --danger-background: #ffdacc;
    --danger-background-glow-high-contrast: #ffdacc;
    --danger-dark: #651b01;
    --danger-hover: #b23001;
    --danger-active: #7f2201;
    --danger-box-shadow: rgba(218, 59, 1, 0.3);
    --danger-invert: white;
    --right-sidebar-width: 0;

    ${mqFrom.desktop} {
      --right-sidebar-width: 230px;
    }
    ${mqFrom.wide} {
      --right-sidebar-width: 300px;
    }
  }

  ${themeLight}

  ${themeHighContrast}


  html {
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  body,
  html {
    font-family: "Segoe UI", SegoeUI, "Segoe WP", "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif;
    /* font-family: 'alliance_no.2regular', sans-serif; */
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  /* h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'alliance_no.2bold', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }

  p {
    font-family: "Segoe UI", SegoeUI, "Segoe WP", "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif;
  } */

  // Defaults
  body {
    background: var(--body-background);
    color: var(--text);
    display: flex;
    flex-direction: column;
    line-height: 160%;
    min-height: 100vh;
    height: 100%;
  }

  body[style] {
    padding-right: 0 !important;
  }

  a,
  li,
  button,
  input {
    outline: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background:  var(--body-background-dark);
  }

  ::-webkit-scrollbar-thumb {
    background:  var(--border);
    border-radius: 6px;
    border: 3px solid var(--body-background-dark);
  }

  :focus {
    outline: none;
  }

  .outer-padding {
    padding-left: 1rem;
    padding-right: 1rem;

    ${mqFrom.tablet} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    ${mqFrom.desktop} {
      padding-left: 5vw;
      padding-right: 5vw;
    }
    ${mqFrom.ultrawide} {
      padding-left: calc(50% - 800px);
      padding-right: calc(50% - 800px);
    }
  }

  .small-outer-padding {
    padding-left: 1rem;
    padding-right: 1rem;

    ${mqFrom.tablet} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    ${mqFrom.desktop} {
      padding-left: calc(50% - 480px);
      padding-right: calc(50% - 480px);
    }
  }

  .fixed-small-width {
    max-width: calc(960px + 2rem);

    ${mqFrom.tablet} {
      max-width: calc(960px + 3rem);
    }

    ${mqFrom.desktop} {
      max-width: calc(960px + 10vw);
    }
    
    ${mqFrom.ultrawide} {
      max-width: calc(100vw - 480px);
    }
  }

  hr {
    border: 1px solid var(--border);
    border-width: 1px 0 0;
    height: 0;
    color: var(--text-subtle);
    margin: 1.5rem  0;
  }

  .breadcrumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${mqUntil.mobile} {
      flex-direction: column;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
  }


  html.theme-dark .theme-display:not(.is-dark),
  html.theme-high-contrast .theme-display:not(.is-high-contrast),
  html.theme-light .theme-display:not(.is-light) {
    display: none;
  }

  html.theme-high-contrast .high-contrast-text-invert {
    color: var(--text-invert)
  }

  .input, .textarea {
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--body-background);
    border: 1px solid var(--border);
    border-radius: 4px;
    box-shadow: inset 0 1px 2px var(--box-shadow-light);
    color: var(--text);
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    max-width: 100%;
    padding: calc(0.375em - 1px) calc(0.625em - 1px);
    position: relative;
    vertical-align: top;
    width: 100%;
  }

  input[type="search"] {
    border: 1px solid var(--border);
    border-radius: 0;
    appearance: none;
    outline: none;
    background-color: var(--body-background);
    box-sizing: border-box;
  }

  a {
    color: var(--primary-base);
    cursor: pointer;
    text-decoration: none;
    word-wrap: break-word;
  }

  a:hover {
    color: var(--primary-hover);
    text-decoration: underline;
  }

  /* a:visited {
    color: var(--visited);
  } */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &.buttons.right {
      justify-content: flex-end;
    }

    .button {
      margin-bottom: 0.5rem;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

  }

  .center {
      justify-content: center !important;
    }

  button {
    color: var(--text);
    background-color: var(--body-background);
  }

  button,
  a {
    &.button {
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      border-color: var(--text-subtle);
      cursor: pointer;
      justify-content: center;
      padding-bottom: calc(0.375em - 1px);
      padding-left: 0.75em;
      padding-right: 0.75em;
      padding-top: calc(0.375em - 1px);
      text-align: center;
      white-space: nowrap;
      text-decoration: none !important;
      display: inline-flex;
      align-items: center;
      line-height: 1.5;
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

      &:hover {
        border-color: var(--primary-hover);
        color: var(--primary-hover);
      }

      &.primary {
        background-color: var(--primary-base);
        border-color: var(--primary-base);
        color: var(--primary-invert);

        &.disabled,
        &[disabled] {
          background-color: var(--primary-base);
          border-color: var(--border-white-high-contrast);
          box-shadow: none;
        }

        &:hover {
          background-color: var(--primary-hover);
        }
      }
      &.small {
        border-radius: 2px;
        font-size: 0.875rem;
      }

      &.disabled,
      &[disabled] {
        background-color: var(--body-background-medium);
        border-color: var(--body-background-dark);
        box-shadow: none;
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.text {
        background-color: transparent;
        border-color: transparent;
        color: var(--text);
        text-decoration: underline;

        &:hover {
          background-color: transparent;
          color: var(--primary-hover);
          text-decoration: underline !important;
        }

        &.link {
          color: var(--primary-base);
          padding: 0;
          height: auto;
          background-color: transparent;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: 600;
  }

  h1,
  h2 {
    line-height: 1.3;
    margin-bottom: 12px;
    font-size: 2.5rem;
    margin-top: 0;
    word-wrap: break-word;
    word-break: break-word;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
    margin-bottom: 12px;
    margin-top: 10px;
  }

  h1,.h1 {
    line-height: 1.3;
    margin-bottom: 12px;
    font-size: 2.5rem;
    margin-top: 0;
    word-wrap: break-word;
    word-break: break-word;

    &:first-of-type {
      margin-top: -10px;
      margin-bottom: 0;
    }
  }

  h2,.h2 {
    font-size: 1.75rem;
    margin-top: 32px;
  }

  h3,.h3 {
    font-size: 1.1875rem;
    margin-top: 30px;
    margin-bottom: 18px;

    span {
      font-size: 1rem;
    }
  }

  /* .content, .details, .paper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    color: var(--primary-base);
    }
  } */

  /* .content, .details, .paper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    color: var(--primary-base);
    }
  } */

  .content, .paper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    color: var(--primary-base);
    }
  }

  .subtitle {
    font-size: 0.75rem;
    letter-spacing: 0.225rem;
    line-height: 1;
    text-transform: uppercase;
  }

  .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
  }
  main {
    flex: 1 1 0;
    background-color: var(--body-background-medium);
  }

  .is-visually-hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
  }

  .is-hidden-mobile {
    ${mqUntil.mobile} {
      display: none !important;
    }
  }
  .is-hidden-tablet {
    ${mqUntil.tablet} {
      display: none;
    }
  }

  .preview-warning {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;

    padding: 1rem;
    background: white;

    p {
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }
  }

  .cookie-policy {
    margin: 3rem 0;

    p {
      margin-top: 1rem;
    }

    ul p {
      margin-top: 0;
    }

    p + h5, p + h3 {
      margin-top: 2rem;
    }

    .CookieDeclarationDialogText, .CookieDeclarationIntro {
      display: none;
    }
  }

  .CookieDeclaration {
    margin-top: 6rem;
  }

  .cookie-policy-page {
    background-color: var(--body-background);
  }

  .lowerCase {
    text-transform: lowercase;
  }

  .no-hover {
    pointer-events: none;
  }

  .drag-drop-icon-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    left: 1rem;
    gap: 0.25rem;
    width: 12px;
    display: none;
  }

  .drag-drop-icon {
      width: 6px;
      height: 6px;
      background-color: black;
      border-radius: 50%;
  }
`;
