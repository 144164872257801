import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const assessmentStatusApi = createApi({
  reducerPath: 'assessmentStatus',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  endpoints: (builder) => ({
    getAssessmentStatus: builder.query<any, any>({
      query(id) {
        return {
          url: `/get-assessment-status?assessmentId=${id}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export default assessmentStatusApi;
