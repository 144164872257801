import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ResponseData {
  sid: string;
}

interface Profile {
  'user-id': string;
  'user-type': string;
  email: string;
  password: string;
  linked: string;
  noProfileData?: boolean;
  'partner-name'?: string;
}

interface RequestDataPut {
  userId: string;
  email: string;
  password: string;
  userType: string;
  linked: boolean;
}

interface RequestData {
  email: string;
  password: string;
  userType: string;
  linked: boolean;
}

export const profileApi = createApi({
  reducerPath: 'profile',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/profile/',
  }),
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    postProfile: builder.mutation<ResponseData, RequestData>({
      query({ email, password, userType, linked }) {
        return {
          url: `/post-profile`,
          method: 'POST',
          body: {
            'user-type': userType,
            email: email,
            password: password,
            linked: linked ? 'Linked' : 'NotLinked',
          },
        };
      },
    }),
    putProfile: builder.mutation<void, RequestDataPut>({
      query({ email, password, userType, userId, linked }) {
        return {
          url: `/put-profile`,
          method: 'PUT',
          body: {
            'user-id': userId,
            'user-type': userType,
            email: email,
            password: password,
            linked: linked ? 'Linked' : 'NotLinked',
          },
        };
      },
    }),
    getProfile: builder.query<Profile, void>({
      query() {
        return {
          url: `/get-profile`,
          method: 'GET',
        };
      },
    }),
  }),
});

export default profileApi;
