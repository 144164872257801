import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const isProduction = process.env.NODE_ENV === 'production';

const appInsightsConnString = isProduction
  ? process.env.APPLICATION_INSIGHTS_CONNECTION_STRING || ''
  : 'InstrumentationKey=fd8d2348-b5e9-44df-b848-d1698780f48d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';

const defaultBrowserHistory = {
  url: '/',
  location: { pathName: '' },
  listen: () => {},
};

let browserHistory = defaultBrowserHistory;

if (typeof window !== 'undefined') {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathName = browserHistory?.state?.url;
}

const reactPlugin = new ReactPlugin();
const appInsights =
  appInsightsConnString &&
  new ApplicationInsights({
    config: {
      connectionString: appInsightsConnString,
      maxBatchSizeInBytes: 10000,
      maxBatchInterval: 15000,
      extension: [reactPlugin],
      extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

if (typeof window !== 'undefined' && appInsightsConnString) {
  appInsights.loadAppInsights();
}

export { appInsights, reactPlugin };
