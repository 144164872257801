import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/baseQuery';

export const assessmentApi = createApi({
  reducerPath: 'assessment',
  baseQuery: baseQuery('/api/assessment'),
  endpoints: (builder) => ({
    getAssessment: builder.query<any, any>({
      query(id) {
        console.log('getAssessment-id', id)
        return {
          url: `/get-assessment=${id}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export default assessmentApi;
