import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import FirstTimeLogin from '../sections/FirstTimeLogin';
import profileApi from '../apiFrontend/profileApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useUserData } from 'utils/user';

const SystemProfile: React.FC = ({ children }) => {
  const { accounts } = useMsal();
  const { accessToken } = useUserData();
  const account = accounts[0];
  const isAuthenticated = useIsAuthenticated();
  const { data: profileData } = profileApi.useGetProfileQuery((!isAuthenticated || !accessToken) && skipToken);

  return (
    <>
      {children}
      {profileData?.noProfileData && isAuthenticated && <FirstTimeLogin />}
    </>
  );
};

export default SystemProfile;
