import * as msal from '@azure/msal-browser';

const scopes = [`${process.env.NEXT_PUBLIC_AZURE_AD_SCOPE}`, 'openid', 'profile', 'offline_access'];
// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_AZURE_AD_AUTHORITY,
    knownAuthorities: [`${process.env.NEXT_PUBLIC_AZURE_AD_KNOWN_AUTHORITIES}`],
    redirectUri: process.env.NEXT_PUBLIC_DOMAIN,
    postLogoutRedirectUri: process.env.NEXT_PUBLIC_DOMAIN,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const loginRequest = {
  scopes,
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
