import { useContext, useEffect } from 'react';
import { InteractionStatus, AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'auth/config';
import { GlobalContext } from 'context/global-context';

const queueKey = 'continia.action.queue';

const debug = true;

export type QueueItemTypes = 'ModuleComplete' | 'UnitRead' | 'QuizComplete';

export type QueueItem = {
  id: string;
  timestamp: Date;
  actionItem: UserAchievement;
};

type UserData = {
  instance: IPublicClientApplication;
  inProgress: InteractionStatus;
  accounts: AccountInfo[];
  dataLoaded: boolean;
  setDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  accessToken: string;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
  setBookmarks: (items: Bookmark[]) => void;
  setAchievements: (items: UserAchievement[]) => void;
  loggedIn: boolean;
  saveAction: (item: UserAchievement) => void;
  achievements: UserAchievement[];
  bookmarks: Bookmark[];
  init: () => void;
  login: () => void;
};

const log = (...args: unknown[]): void => {
  if (debug) {
    console.log('[useUserData]', ...args);
  }
};

export const useUserData = (): UserData => {
  const { accounts, inProgress, instance } = useMsal();
  const {
    accessToken,
    setAccessToken,
    achievements,
    setAchievements,
    bookmarks,
    setBookmarks,
    dataLoaded,
    setDataLoaded,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (inProgress === 'none' && accessToken && accounts.length === 0) {
      setAccessToken('');
    }
  }, [inProgress, accessToken, accounts, setAccessToken]);

  const init = (): void => {
    log('init');
    const queue = getQueue();
    const uniqueAcheivements = [...achievements];

    queue.forEach((item) => {
      if (!uniqueAcheivements.find((achievement) => achievement.contentId === item.contentId)) {
        uniqueAcheivements.push(item);
      }
    });

    setAchievementsWithoutDublicates([...uniqueAcheivements]);
    setDataLoaded(true);
  };

  const login = (): void => {
    instance.loginRedirect(loginRequest);
  };

  const sendAction = async (timestamp: Date, accessToken: string, item: UserAchievement): Promise<void> => {
    const response = await fetch('/api/set-action', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        timestamp: timestamp.valueOf(),
        accessToken,
        item,
      }),
    });

    if (response.status === 200) {
      const achievements = await response.json();
      setAchievementsWithoutDublicates(achievements);
    } else {
      // push to queue for later user
      pushToQueue(item);
      console.log('@error', response);
      // alert('der skete en fejl');
    }
  };

  const saveAction = (item: UserAchievement): void => {
    log('saveAction', item);

    if (!actionCompleted(item)) {
      if (accessToken) {
        // if logged in, send to api
        // console.log('@@ saveAction -> sendAction', item);
        setTimeout(() => {
          setAchievementsWithoutDublicates([...achievements, item]); // Optimistic update
        }, 200);
        sendAction(new Date(), accessToken, item);
      } else {
        // else push to localStorage queue
        // console.log('@@ saveAction -> pushToQueue', item);
        pushToQueue(item);
        setTimeout(() => {
          setAchievementsWithoutDublicates([...achievements, item]); // Optimistic update
        }, 200);
        //   setTimeout(() => {
        //     console.log('@setTimeout', [...achievements, item]);
        //   }, 2000);
      }
    }
  };

  const actionCompleted = (item: UserAchievement): boolean => {
    return !!achievements?.find((ach) => ach.type === item.type && ach.contentId === item.contentId);
  };

  const setAchievementsWithoutDublicates = (newAchievements: UserAchievement[]): void => {
    log('setAchievementsWithoutDublicates', newAchievements);

    const uniqueAcheivements = [...achievements, ...newAchievements].reduce((arr, item) => {
      if (!arr.find((ach) => ach.type === item.type && ach.contentId === item.contentId)) {
        return [...arr, item];
      }
      return arr;
    }, []);
    setAchievements([...uniqueAcheivements]);
  };

  return {
    instance,
    accounts,
    inProgress,
    accessToken,
    setAccessToken,
    achievements,
    setDataLoaded,
    setAchievements: setAchievementsWithoutDublicates,
    saveAction,
    dataLoaded,
    init,
    login,
    setBookmarks,
    bookmarks,
    loggedIn: !!accessToken && accounts.length > 0 && inProgress === 'none',
  };
};

// Bruges fra account-handler.tsx til at sende når der logget på
export const clearQueue = (): void => {
  localStorage.setItem(queueKey, JSON.stringify([]));
};

export const getQueue = (): UserAchievement[] => {
  const queue = JSON.parse(localStorage.getItem(queueKey) || '[]');
  return queue;
};

const pushToQueue = (item: UserAchievement): void => {
  const queue = getQueue();
  if (!queue.find((ach) => ach.contentId === item.contentId)) {
    queue.push(item);
    localStorage.setItem(queueKey, JSON.stringify(queue));
  }
};

export const isUnitCompleted = (achievements: UserAchievement[], unit: Unit | Quiz): boolean => {
  return !!achievements.find(
    (ach) =>
      ach.contentId === unit.meta.id && ach.type === (unit.meta.type === 'Quiz' ? 'QuizComplete' : 'UnitComplete')
  );
};

export const isModuleCompleted = (achievements: UserAchievement[], mod: Module | Assessment): boolean => {
  return !!achievements.find(
    (ach) =>
      ach.contentId === mod.meta.id &&
      ach.type === (mod.meta.type === 'Assessment' ? 'AssessmentComplete' : 'ModuleComplete')
  );
};

export const isLearningPathComplete = (achievements: UserAchievement[], learningPath: LearningPath): boolean => {
  const isCompleted = !!achievements.find(
    (ach) => ach.contentId === learningPath.meta.id && ach.type === 'LearningPathComplete'
  );
  if (isCompleted) {
    return true;
  }
  return learningPath.modules.every((item) => isModuleCompleted(achievements, item));
};

enum StatusTypes {
  inProgress = 'In Progress',
  notStarted = 'Not Started',
  completed = 'Completed',
}

export const learningPathStatus = (achievements: UserAchievement[], learningPath: LearningPath): StatusTypes => {
  const isCompleted = !!learningPath.modules.every((item) => isModuleCompleted(achievements, item));

  if (isCompleted) {
    return StatusTypes.completed;
  }

  const inProgress = learningPath.modules.some((module) => {
    if (module.meta.type === "Module") {
      return (module as Module).units.some((unit) => isUnitCompleted(achievements, unit));
    } else {
      return isModuleCompleted(achievements, module);
    }
  });

  if (inProgress) {
    return StatusTypes.inProgress;
  }

  return StatusTypes.notStarted;
};

export const moduleStatus = (achievements: UserAchievement[], mod: Module | Assessment): StatusTypes => {
  const isCompleted = isModuleCompleted(achievements, mod);

  if (isCompleted) {
    return StatusTypes.completed;
  }

  const inProgress = (mod as Module).units.some((unit) => isUnitCompleted(achievements, unit));

  if (inProgress) {
    return StatusTypes.inProgress;
  }

  return StatusTypes.notStarted;
};

export const assessmentStatus = (achievements: UserAchievement[], mod: Module | Assessment, status: 'NeverStarted' | 'InProgress' | 'Failed' | 'Passed'): StatusTypes => {
  const isCompleted = isModuleCompleted(achievements, mod);

  if (isCompleted) {
    return StatusTypes.completed;
  }

  if(status === 'Failed') {
    return StatusTypes.inProgress;
  }

  return StatusTypes.notStarted;
};

export const unitStatus = (achievements: UserAchievement[], unit: Unit | Quiz): StatusTypes => {
  const isCompleted = isUnitCompleted(achievements, unit);

  if (isCompleted || unit.type === 'Unit') {
    return StatusTypes.completed;
  }

  return StatusTypes.inProgress;
};